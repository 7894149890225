<template>
  <div class="calls-table">
    <v-container grid-list-xl fluid>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title>{{
              $vuetify.lang.t('$vuetify.different.filter')
            }}</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="6" md="2">
                  <v-menu
                    ref="filterDatePicker"
                    v-model="filterDatePicker"
                    :close-on-content-click="false"
                    :return-value.sync="filters.dates"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="filters.dates"
                        :label="$vuetify.lang.t('$vuetify.dates.dates')"
                        prepend-icon="event"
                        clearable
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      first-day-of-week="1"
                      range
                      v-model="filters.dates"
                      no-title
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="filterDatePicker = false"
                        >Cancel</v-btn
                      >
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.filterDatePicker.save(filters.dates)"
                        >OK</v-btn
                      >
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-select
                    v-model="filters.types"
                    :items="companyTypes"
                    :menu-props="{ maxHeight: '400' }"
                    :label="$vuetify.lang.t('$vuetify.different.type')"
                    multiple
                    persistent-hint
                    chips
                    clearable
                    item-value="id"
                    item-text="title"
                    prepend-icon="list"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-select
                    v-model="filters.assigned"
                    :items="compUsers"
                    :menu-props="{ maxHeight: '400' }"
                    :label="$vuetify.lang.t('$vuetify.user.assigned')"
                    multiple
                    :hint="$vuetify.lang.t('$vuetify.user.selectAssigned')"
                    persistent-hint
                    chips
                    deletable-chips
                    clearable
                    item-value="id"
                    item-text="title"
                    prepend-icon="mdi-account-multiple"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-select
                    v-model="filters.statuses"
                    :items="compCallStatuses"
                    :menu-props="{ maxHeight: '400' }"
                    :label="$vuetify.lang.t('$vuetify.status.status')"
                    multiple
                    :hint="$vuetify.lang.t('$vuetify.status.selectStatus')"
                    persistent-hint
                    chips
                    deletable-chips
                    clearable
                    item-value="id"
                    item-text="title"
                    prepend-icon="list"
                  ></v-select>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card>
            <v-card-title>{{
              $vuetify.lang.t('$vuetify.call.many')
            }}</v-card-title>
            <v-toolbar flat color="white">
              <v-text-field
                text
                solo
                flat
                clearable
                prepend-icon="search"
                :placeholder="$vuetify.lang.t('$vuetify.toolbar.search')"
                v-model="search"
                hide-details
                class="hidden-sm-and-down"
              ></v-text-field>
              <v-spacer></v-spacer>
              <v-btn small rounded @click="getItems" class="mr-2">
                <v-icon>mdi-refresh</v-icon>
                {{ $vuetify.lang.t('$vuetify.buttons.refresh') }}
              </v-btn>
              <!--              <v-btn small rounded color="success" @click="dialog = !dialog">
                              <v-icon>mdi-plus</v-icon>
                              {{ $vuetify.lang.t('$vuetify.buttons.create') }}
                            </v-btn>-->
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text class="pa-0">
              <v-data-table
                :headers="compHeader"
                :items="items"
                fixed-header
                class="elevation-1"
                item-key="id"
                :loading="tableLoading"
                :options.sync="options"
                :server-items-length="totalItems"
              >
                <template v-slot:item.company_id="{ item }">
                  {{ item.company ? item.company.name : item.company_id }}
                </template>
                <template v-slot:item.company_type="{ item }">
                  {{
                    item.company && item.company.type
                      ? item.company.type.key
                      : ''
                  }}
                </template>
                <template v-slot:item.company="{ item }">
                  <v-chip
                    outlined
                    pill
                    v-for="entity in item.company.users"
                    :key="entity.id"
                  >
                    <v-avatar left>
                      <img
                        alt="Avatar"
                        :src="entity.avatar ? entity.avatar : defaultAvatar"
                      />
                    </v-avatar>
                    {{ entity.name }} {{ entity.surname }}
                  </v-chip>
                </template>
                <template v-slot:item.call_1_action="{ item }">
                  <small>{{
                    item.call_1_action ? item.call_1_action.date_short : ''
                  }}</small>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-on="on"
                        v-bind="attrs"
                        @click="
                          actionItem(
                            item,
                            'call_1',
                            'CALL1(CC)',
                            item.call_1_action
                          )
                        "
                      >
                        <v-icon
                          :color="
                            item.call_1_action
                              ? item.call_1_action.marker.color
                              : 'indigo'
                          "
                          >mdi-pencil
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>CALL1(CC)</span>
                  </v-tooltip>
                  <v-icon
                    v-show="item.call_1_action && item.call_1_action.active"
                    color="light-blue accent-2"
                    >mdi-flag-checkered
                  </v-icon>
                </template>
                <template v-slot:item.call_2_action="{ item }">
                  <small>{{
                    item.call_2_action ? item.call_2_action.date_short : ''
                  }}</small>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-on="on"
                        v-bind="attrs"
                        @click="
                          actionItem(
                            item,
                            'call_2',
                            'CALL2(CC/Manager)',
                            item.call_2_action
                          )
                        "
                      >
                        <v-icon
                          :color="
                            item.call_2_action
                              ? item.call_2_action.marker.color
                              : 'indigo'
                          "
                          >mdi-pencil
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>CALL2(CC/Manager)</span>
                  </v-tooltip>
                  <v-icon
                    v-show="item.call_2_action && item.call_2_action.active"
                    color="light-blue accent-2"
                    >mdi-flag-checkered
                  </v-icon>
                </template>
                <template v-slot:item.email_action="{ item }">
                  <small>{{
                    item.email_action ? item.email_action.date_short : ''
                  }}</small>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-on="on"
                        v-bind="attrs"
                        @click="
                          actionItem(item, 'email', 'EMAIL', item.email_action)
                        "
                      >
                        <v-icon
                          :color="
                            item.email_action
                              ? item.email_action.marker.color
                              : 'indigo'
                          "
                          >mdi-pencil
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>EMAIL</span>
                  </v-tooltip>
                  <v-icon
                    v-show="item.email_action && item.email_action.active"
                    color="light-blue accent-2"
                    >mdi-flag-checkered
                  </v-icon>
                </template>
                <template v-slot:item.call_3_action="{ item }">
                  <small>{{
                    item.call_3_action ? item.call_3_action.date_short : ''
                  }}</small>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-on="on"
                        v-bind="attrs"
                        @click="
                          actionItem(
                            item,
                            'call_3',
                            'CALL3(CC/Manager)',
                            item.call_3_action
                          )
                        "
                      >
                        <v-icon
                          :color="
                            item.call_3_action
                              ? item.call_3_action.marker.color
                              : 'indigo'
                          "
                          >mdi-pencil
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>CALL3(CC/Manager)</span>
                  </v-tooltip>
                  <v-icon
                    v-show="item.call_3_action && item.call_3_action.active"
                    color="light-blue accent-2"
                    >mdi-flag-checkered
                  </v-icon>
                </template>
                <template v-slot:item.sample_action="{ item }">
                  <small>{{
                    item.sample_action ? item.sample_action.date_short : ''
                  }}</small>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-on="on"
                        v-bind="attrs"
                        @click="
                          actionItem(
                            item,
                            'sample',
                            $vuetify.lang.t('$vuetify.order.sample'),
                            item.sample_action
                          )
                        "
                      >
                        <v-icon
                          :color="
                            item.sample_action
                              ? item.sample_action.marker.color
                              : 'indigo'
                          "
                          >mdi-pencil
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $vuetify.lang.t('$vuetify.order.sample') }}</span>
                  </v-tooltip>
                  <v-icon
                    v-show="item.sample_action && item.sample_action.active"
                    color="light-blue accent-2"
                    >mdi-flag-checkered
                  </v-icon>
                </template>
                <template v-slot:item.call_4_action="{ item }">
                  <small>{{
                    item.call_4_action ? item.call_4_action.date_short : ''
                  }}</small>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-on="on"
                        v-bind="attrs"
                        @click="
                          actionItem(
                            item,
                            'call_4',
                            'CALL4(CC/Manager)',
                            item.call_4_action
                          )
                        "
                      >
                        <v-icon
                          :color="
                            item.call_4_action
                              ? item.call_4_action.marker.color
                              : 'indigo'
                          "
                          >mdi-pencil
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>CALL4(CC/Manager)</span>
                  </v-tooltip>
                  <v-icon
                    v-show="item.call_4_action && item.call_4_action.active"
                    color="light-blue accent-2"
                    >mdi-flag-checkered
                  </v-icon>
                </template>
                <template v-slot:item.order_action="{ item }">
                  <small>{{
                    item.order_action ? item.order_action.date_short : ''
                  }}</small>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-on="on"
                        v-bind="attrs"
                        @click="
                          actionItem(
                            item,
                            'order',
                            $vuetify.lang.t('$vuetify.order.order'),
                            item.order_action
                          )
                        "
                      >
                        <v-icon
                          :color="
                            item.order_action
                              ? item.order_action.marker.color
                              : 'indigo'
                          "
                          >mdi-pencil
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $vuetify.lang.t('$vuetify.order.order') }}</span>
                  </v-tooltip>
                  <v-icon
                    v-show="item.order_action && item.order_action.active"
                    color="light-blue accent-2"
                    >mdi-flag-checkered
                  </v-icon>
                </template>
                <template v-slot:item.next_call_action="{ item }">
                  <small>{{
                    item.next_call_action
                      ? item.next_call_action.date_short
                      : ''
                  }}</small>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-on="on"
                        v-bind="attrs"
                        @click="
                          actionItem(
                            item,
                            'next_call',
                            $vuetify.lang.t('$vuetify.call.next'),
                            item.next_call_action
                          )
                        "
                      >
                        <v-icon
                          :color="
                            item.next_call_action
                              ? item.next_call_action.marker.color
                              : 'indigo'
                          "
                        >
                          mdi-pencil
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $vuetify.lang.t('$vuetify.call.next') }}</span>
                  </v-tooltip>
                  <v-icon
                    v-show="
                      item.next_call_action && item.next_call_action.active
                    "
                    color="light-blue accent-2"
                    >mdi-flag-checkered
                  </v-icon>
                </template>
                <template v-slot:item.other_action="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-on="on"
                        v-bind="attrs"
                        @click="
                          actionItem(
                            item,
                            'other',
                            $vuetify.lang.t('$vuetify.different.other'),
                            item.other_action
                          )
                        "
                      >
                        <v-icon
                          :color="item.other_action ? 'orange' : 'indigo'"
                        >
                          mdi-pencil
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{
                      $vuetify.lang.t('$vuetify.different.other')
                    }}</span>
                  </v-tooltip>
                  <v-icon
                    v-show="item.other_action && item.other_action.active"
                    color="light-blue accent-2"
                    >mdi-flag-checkered
                  </v-icon>
                </template>

                <template v-slot:item.action="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-on="on"
                        v-bind="attrs"
                        @click="viewItem(item)"
                      >
                        <v-icon>mdi-magnify</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $vuetify.lang.t('$vuetify.buttons.view') }}</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-show="item.is_next_call"
                        icon
                        v-on="on"
                        v-bind="attrs"
                        @click="viewHistoryItem(item)"
                      >
                        <v-icon>mdi-history</v-icon>
                      </v-btn>
                    </template>
                    <span>{{
                      $vuetify.lang.t('$vuetify.buttons.history')
                    }}</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :color="item.active ? 'green' : 'red'"
                        icon
                        v-on="on"
                        v-bind="attrs"
                        @click="active(item)"
                      >
                        <v-icon>{{ item.active ? 'done' : 'clear' }}</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $vuetify.lang.t('$vuetify.status.active') }}</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog persistent v-model="dialog" max-width="800">
      <v-card>
        <v-card-title>
          <span class="headline">{{ compActionHeader }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="actionForm" lazy-validation v-model="valid">
              <v-row>
                <v-col cols="12" sm="6" md="4" v-show="compShowDate">
                  <v-menu
                    v-model="actionDatePicker"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="250px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="action.date"
                        :label="$vuetify.lang.t('$vuetify.dates.date') + '*'"
                        prepend-icon="event"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :disabled="compDisableDate"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      :min="nowDate"
                      first-day-of-week="1"
                      v-model="action.date"
                      @input="actionDatePicker = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6" md="4" v-show="compShowResultSelect">
                  <v-select
                    v-model="action.marker_id"
                    :items="compResults"
                    :label="$vuetify.lang.t('$vuetify.status.status') + '*'"
                    :hint="$vuetify.lang.t('$vuetify.status.selectStatus')"
                    persistent-hint
                    item-value="id"
                    item-text="title"
                    prepend-icon="list"
                    :disabled="compDisableResult"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="4" v-show="compShowOrderInput">
                  <v-text-field
                    v-model="selectedItem.order_number"
                    type="number"
                    step="1"
                    :disabled="compDisableOrder"
                    clearable
                    :label="$vuetify.lang.t('$vuetify.order.number') + '*'"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                  v-show="action.user.id && compShowUser"
                >
                  <v-avatar left size="50px">
                    <img
                      alt="Avatar"
                      :src="
                        action.user.avatar ? action.user.avatar : defaultAvatar
                      "
                    />
                  </v-avatar>
                  <span class="ml-2 text--primary">
                    {{ action.user ? action.user.name : '' }}
                    {{ action.user ? action.user.surname : '' }}
                  </span>
                </v-col>
                <v-col cols="12" v-if="action.comments.length">
                  <v-list three-line>
                    <v-subheader
                      v-text="$vuetify.lang.t('$vuetify.different.comments')"
                    ></v-subheader>
                    <template v-for="(item, index) in action.comments">
                      <v-list-item :key="item.id">
                        <v-list-item-avatar>
                          <v-img
                            :src="
                              item.user.avatar
                                ? item.user.avatar
                                : defaultAvatar
                            "
                          ></v-img>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title class="justify-space-between">
                            <span class="mr-2"
                              >{{ item.user.name }}
                              {{ item.user.surname }}</span
                            >
                            <small>{{ item.created_at }}</small>
                          </v-list-item-title>
                          <v-list-item-subtitle>{{
                            item.comment
                          }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider
                        :key="index + '_' + item.id"
                        v-if="index !== action.comments.length - 1"
                        inset
                      ></v-divider>
                    </template>
                  </v-list>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="actionComment"
                    :label="$vuetify.lang.t('$vuetify.different.comment')"
                    counter
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>*{{ $vuetify.lang.t('$vuetify.different.indicate') }}</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
            :disabled="actionLoading"
          >
            {{ $vuetify.lang.t('$vuetify.buttons.close') }}
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="saveAction"
            :disabled="!valid"
            :loading="actionLoading"
          >
            {{ $vuetify.lang.t('$vuetify.buttons.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogCompany" max-width="500">
      <v-card max-width="500" class="mx-auto">
        <v-img src="/static/img/default-company.jpg" height="250" dark>
          <v-row>
            <v-spacer></v-spacer>
            <v-card-title>
              <v-btn dark icon @click="dialogCompany = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
          </v-row>

          <v-row class="fill-height">
            <v-card-title class="white--text">
              <div class="display-1 pl-4">
                {{ company.name }}
              </div>
            </v-card-title>
          </v-row>
        </v-img>

        <v-list two-line>
          <v-list-item v-for="(phone, index) in company.phones" :key="phone.id">
            <v-list-item-icon v-if="index === 0">
              <v-icon color="indigo">
                mdi-phone
              </v-icon>
            </v-list-item-icon>
            <v-list-item-action v-else></v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>{{ phone.phone }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider v-if="company.emails" inset></v-divider>

          <v-list-item v-for="(email, index) in company.emails" :key="email.id">
            <v-list-item-icon v-if="index === 0">
              <v-icon color="indigo">
                mdi-email
              </v-icon>
            </v-list-item-icon>
            <v-list-item-action v-else></v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>{{ email.email }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider inset></v-divider>

          <v-list-item>
            <v-list-item-icon>
              <v-icon color="indigo">
                mdi-map-marker
              </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title
                >{{ company.number }} {{ company.street }}</v-list-item-title
              >
              <v-list-item-subtitle>
                {{ company.city ? company.city.name : '' }},
                {{ company.postcode }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-divider v-if="company.web" inset></v-divider>

          <v-list-item v-if="company.web">
            <v-list-item-icon>
              <v-icon color="indigo">
                mdi-information
              </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ company.web }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider v-if="company.contacts.length" inset></v-divider>

          <v-list-item
            v-for="(contact, index) in company.contacts"
            :key="contact.id"
          >
            <v-list-item-icon v-if="index === 0">
              <v-icon color="indigo">
                mdi-account
              </v-icon>
            </v-list-item-icon>
            <v-list-item-action v-else></v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>{{ contact.name }}</v-list-item-title>
              <v-list-item-subtitle class="text-wrap">
                <span
                  v-for="(contactPhone, cIndex) in contact.phones"
                  :key="contactPhone.phone"
                >
                  {{ contactPhone.phone
                  }}<span
                    v-if="
                      (contact.phones.length > 1 &&
                        cIndex < contact.phones.length - 1) ||
                        contact.emails.length
                    "
                    >,</span
                  >
                </span>
                <span
                  v-for="(contactEmail, eIndex) in contact.emails"
                  :key="contactEmail.email"
                >
                  {{ contactEmail.email
                  }}<span
                    v-if="
                      contact.emails.length > 1 &&
                        eIndex < contact.emails.length - 1
                    "
                    >,</span
                  >
                </span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-card-text>
          <v-select
            v-model="selectedItem.company.users"
            :items="compUsers"
            :menu-props="{ maxHeight: '400' }"
            :label="$vuetify.lang.t('$vuetify.user.assignTo')"
            multiple
            :hint="$vuetify.lang.t('$vuetify.user.selectAssigned')"
            persistent-hint
            chips
            deletable-chips
            return-object
            item-text="title"
            prepend-icon="mdi-account-multiple"
            @change="syncUsersToCompany"
          ></v-select>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogHistory"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="dialogHistory = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{
            $vuetify.lang.t('$vuetify.buttons.history')
          }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items> </v-toolbar-items>
        </v-toolbar>
        <v-data-table
          :headers="compHistoryHeader"
          :items="historyItems"
          fixed-header
          class="elevation-1"
          item-key="id"
          :loading="tableHistoryLoading"
          :options.sync="historyOptions"
          :server-items-length="totalHistoryItems"
        >
          <template v-slot:item.company_id="{ item }">
            {{ item.company ? item.company.name : item.company_id }}
          </template>
          <template v-slot:item.company_type="{ item }">
            {{ item.company && item.company.type ? item.company.type.key : '' }}
          </template>
          <template v-slot:item.company="{ item }">
            <v-chip
              outlined
              pill
              v-for="entity in item.company.users"
              :key="entity.id"
            >
              <v-avatar left>
                <img
                  alt="Avatar"
                  :src="entity.avatar ? entity.avatar : defaultAvatar"
                />
              </v-avatar>
              {{ entity.name }} {{ entity.surname }}
            </v-chip>
          </template>
          <template v-slot:item.call_1_action="{ item }">
            <small>{{
              item.call_1_action ? item.call_1_action.date_short : ''
            }}</small>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-on="on"
                  v-bind="attrs"
                  @click="
                    actionItem(item, 'call_1', 'CALL1(CC)', item.call_1_action)
                  "
                >
                  <v-icon
                    :color="
                      item.call_1_action
                        ? item.call_1_action.marker.color
                        : 'indigo'
                    "
                    >mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
              <span>CALL1(CC)</span>
            </v-tooltip>
            <v-icon
              v-show="item.call_1_action && item.call_1_action.active"
              color="light-blue accent-2"
              >mdi-flag-checkered
            </v-icon>
          </template>
          <template v-slot:item.call_2_action="{ item }">
            <small>{{
              item.call_2_action ? item.call_2_action.date_short : ''
            }}</small>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-on="on"
                  v-bind="attrs"
                  @click="
                    actionItem(
                      item,
                      'call_2',
                      'CALL2(CC/Manager)',
                      item.call_2_action
                    )
                  "
                >
                  <v-icon
                    :color="
                      item.call_2_action
                        ? item.call_2_action.marker.color
                        : 'indigo'
                    "
                    >mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
              <span>CALL2(CC/Manager)</span>
            </v-tooltip>
            <v-icon
              v-show="item.call_2_action && item.call_2_action.active"
              color="light-blue accent-2"
              >mdi-flag-checkered
            </v-icon>
          </template>
          <template v-slot:item.email_action="{ item }">
            <small>{{
              item.email_action ? item.email_action.date_short : ''
            }}</small>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-on="on"
                  v-bind="attrs"
                  @click="actionItem(item, 'email', 'EMAIL', item.email_action)"
                >
                  <v-icon
                    :color="
                      item.email_action
                        ? item.email_action.marker.color
                        : 'indigo'
                    "
                    >mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
              <span>EMAIL</span>
            </v-tooltip>
            <v-icon
              v-show="item.email_action && item.email_action.active"
              color="light-blue accent-2"
              >mdi-flag-checkered
            </v-icon>
          </template>
          <template v-slot:item.call_3_action="{ item }">
            <small>{{
              item.call_3_action ? item.call_3_action.date_short : ''
            }}</small>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-on="on"
                  v-bind="attrs"
                  @click="
                    actionItem(
                      item,
                      'call_3',
                      'CALL3(CC/Manager)',
                      item.call_3_action
                    )
                  "
                >
                  <v-icon
                    :color="
                      item.call_3_action
                        ? item.call_3_action.marker.color
                        : 'indigo'
                    "
                    >mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
              <span>CALL3(CC/Manager)</span>
            </v-tooltip>
            <v-icon
              v-show="item.call_3_action && item.call_3_action.active"
              color="light-blue accent-2"
              >mdi-flag-checkered
            </v-icon>
          </template>
          <template v-slot:item.sample_action="{ item }">
            <small>{{
              item.sample_action ? item.sample_action.date_short : ''
            }}</small>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-on="on"
                  v-bind="attrs"
                  @click="
                    actionItem(
                      item,
                      'sample',
                      $vuetify.lang.t('$vuetify.order.sample'),
                      item.sample_action
                    )
                  "
                >
                  <v-icon
                    :color="
                      item.sample_action
                        ? item.sample_action.marker.color
                        : 'indigo'
                    "
                    >mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $vuetify.lang.t('$vuetify.order.sample') }}</span>
            </v-tooltip>
            <v-icon
              v-show="item.sample_action && item.sample_action.active"
              color="light-blue accent-2"
              >mdi-flag-checkered
            </v-icon>
          </template>
          <template v-slot:item.call_4_action="{ item }">
            <small>{{
              item.call_4_action ? item.call_4_action.date_short : ''
            }}</small>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-on="on"
                  v-bind="attrs"
                  @click="
                    actionItem(
                      item,
                      'call_4',
                      'CALL4(CC/Manager)',
                      item.call_4_action
                    )
                  "
                >
                  <v-icon
                    :color="
                      item.call_4_action
                        ? item.call_4_action.marker.color
                        : 'indigo'
                    "
                    >mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
              <span>CALL4(CC/Manager)</span>
            </v-tooltip>
            <v-icon
              v-show="item.call_4_action && item.call_4_action.active"
              color="light-blue accent-2"
              >mdi-flag-checkered
            </v-icon>
          </template>
          <template v-slot:item.order_action="{ item }">
            <small>{{
              item.order_action ? item.order_action.date_short : ''
            }}</small>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-on="on"
                  v-bind="attrs"
                  @click="
                    actionItem(
                      item,
                      'order',
                      $vuetify.lang.t('$vuetify.order.order'),
                      item.order_action
                    )
                  "
                >
                  <v-icon
                    :color="
                      item.order_action
                        ? item.order_action.marker.color
                        : 'indigo'
                    "
                    >mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $vuetify.lang.t('$vuetify.order.order') }}</span>
            </v-tooltip>
            <v-icon
              v-show="item.order_action && item.order_action.active"
              color="light-blue accent-2"
              >mdi-flag-checkered
            </v-icon>
          </template>
          <template v-slot:item.next_call_action="{ item }">
            <small>{{
              item.next_call_action ? item.next_call_action.date_short : ''
            }}</small>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-on="on"
                  v-bind="attrs"
                  @click="
                    actionItem(
                      item,
                      'next_call',
                      $vuetify.lang.t('$vuetify.call.next'),
                      item.next_call_action
                    )
                  "
                >
                  <v-icon
                    :color="
                      item.next_call_action
                        ? item.next_call_action.marker.color
                        : 'indigo'
                    "
                  >
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $vuetify.lang.t('$vuetify.call.next') }}</span>
            </v-tooltip>
            <v-icon
              v-show="item.next_call_action && item.next_call_action.active"
              color="light-blue accent-2"
              >mdi-flag-checkered
            </v-icon>
          </template>
          <template v-slot:item.other_action="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-on="on"
                  v-bind="attrs"
                  @click="
                    actionItem(
                      item,
                      'other',
                      $vuetify.lang.t('$vuetify.different.other'),
                      item.other_action
                    )
                  "
                >
                  <v-icon :color="item.other_action ? 'orange' : 'indigo'">
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $vuetify.lang.t('$vuetify.different.other') }}</span>
            </v-tooltip>
            <v-icon
              v-show="item.other_action && item.other_action.active"
              color="light-blue accent-2"
              >mdi-flag-checkered
            </v-icon>
          </template>

          <template v-slot:item.action="{ item }"> </template>
        </v-data-table>
      </v-card>
    </v-dialog>
    <v-snackbar
      :timeout="3000"
      bottom
      right
      :color="snackbar.color"
      v-model="snackbar.show"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text @click.native="snackbar.show = false" icon>
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <AppLoader :dialog.sync="loading"></AppLoader>
  </div>
</template>

<script>
import AppLoader from '@/components/AppLoader'

export default {
  components: {
    AppLoader
  },
  data() {
    return {
      dialog: false,
      dialogCompany: false,
      dialogHistory: false,
      valid: true,
      loading: false,
      actionLoading: false,
      tableLoading: false,
      tableHistoryLoading: false,
      search: null,
      awaitingSearch: false,
      filterDatePicker: false,
      actionDatePicker: false,
      defaultAvatar: 'static/img/av.png',
      snackbar: {
        show: false,
        text: '',
        color: ''
      },
      actionComment: null,
      actionTitle: null,
      actionName: null,
      action: {
        id: null,
        call_id: null,
        user_id: null,
        marker_id: 1,
        date: null,
        active: null,
        action_name: null,
        created_at: null,
        updated_at: null,
        user: {
          avatar: null
        },
        comments: []
      },
      emptyAction: {
        id: null,
        call_id: null,
        user_id: null,
        marker_id: 1,
        date: null,
        active: null,
        action_name: null,
        created_at: null,
        updated_at: null,
        user: {
          avatar: null
        },
        comments: []
      },
      selectedIndex: null,
      selectedItem: {
        company_id: null,
        company: {
          name: null
        }
      },
      selectedHistoryIndex: null,
      selectedHistoryItem: {
        company_id: null,
        company: {
          name: null
        }
      },
      emptyItem: {
        company_id: null,
        company: {
          name: null
        }
      },
      company: {
        id: null,
        name: null,
        street: null,
        number: null,
        postcode: null,
        city_id: null,
        region_id: null,
        type_id: null,
        web: null,
        called: null,
        created_at: null,
        updated_at: null,
        contacts: [],
        phones: [],
        users: []
      },
      companyTypes: [
        {
          id: 1,
          title: 'S'
        },
        {
          id: 2,
          title: 'V'
        }
      ],
      results: [
        {
          id: 1,
          title: 'S'
        },
        {
          id: 2,
          title: 'V'
        }
      ],
      notEmpty: [
        (v) => !!v || this.$vuetify.lang.t('$vuetify.validation.required')
      ],
      max255: [
        (v) => !!v || this.$vuetify.lang.t('$vuetify.validation.required'),
        (v) =>
          (v && v.length <= 255) ||
          this.$vuetify.lang.t('$vuetify.validation.max255')
      ],
      totalItems: 0,
      options: {
        itemsPerPage: 10
      },
      totalHistoryItems: 0,
      historyOptions: {
        itemsPerPage: 15
      },
      items: [],
      historyItems: [],
      filters: {
        types: localStorage.getItem('call_types')
          ? JSON.parse(localStorage.getItem('call_types'))
          : null,
        statuses: localStorage.getItem('call_statuses')
          ? JSON.parse(localStorage.getItem('call_statuses'))
          : null,
        assigned: localStorage.getItem('call_assigned')
          ? JSON.parse(localStorage.getItem('call_assigned'))
          : null,
        dates: localStorage.getItem('call_dates')
          ? JSON.parse(localStorage.getItem('call_dates'))
          : null
      },
      nowDate: new Date().toISOString().slice(0, 10)
    }
  },
  watch: {
    dialog: function(val) {
      if (!val) {
        if (!this.dialogHistory) {
          this.selectedItem = Object.assign({}, this.emptyItem)
          this.selectedIndex = null
        }
        this.selectedAction = null
        this.actionTitle = null
        this.actionName = null
        this.actionComment = null
        this.action = Object.assign({}, this.emptyAction)
      }
    },
    dialogHistory: function(val) {
      if (!val) {
        this.selectedItem = Object.assign({}, this.emptyItem)
        this.selectedIndex = null
        this.selectedAction = null
        this.actionTitle = null
        this.actionName = null
        this.actionComment = null
        this.action = Object.assign({}, this.emptyAction)
        this.historyItems = []
        this.selectedHistoryIndex = null
        this.selectedHistoryItem = Object.assign({}, this.emptyItem)
      }
    },
    dialogCompany: function(val) {
      if (!val) {
        this.selectedItem = Object.assign({}, this.emptyItem)
        this.selectedIndex = null
      }
    },
    search(val) {
      if (!val || (val && val.length >= 3)) {
        if (!this.awaitingSearch) {
          setTimeout(() => {
            this.getItems()
            this.awaitingSearch = false
          }, 1000)
        }
        this.awaitingSearch = true
      }
    },
    options: {
      handler() {
        this.getItems()
      },
      deep: true
    },
    filters: {
      handler() {
        this.getItems()
      },
      deep: true
    },
    'filters.types': function(val) {
      localStorage.setItem('call_types', JSON.stringify(val))
    },
    'filters.statuses': function(val) {
      localStorage.setItem('call_statuses', JSON.stringify(val))
    },
    'filters.assigned': function(val) {
      localStorage.setItem('call_assigned', JSON.stringify(val))
    },
    'filters.dates': function(val) {
      localStorage.setItem('call_dates', JSON.stringify(val))
    }
  },
  metaInfo() {
    return {
      title: this.$vuetify.lang.t('$vuetify.call.many')
    }
  },
  computed: {
    compCallStatuses() {
      return [
        {
          id: 1,
          title: this.$vuetify.lang.t('$vuetify.status.active')
        },
        {
          id: 0,
          title: this.$vuetify.lang.t('$vuetify.status.inactive')
        },
        {
          id: 2,
          title: this.$vuetify.lang.t('$vuetify.status.closed')
        },
        {
          id: 3,
          title: this.$vuetify.lang.t('$vuetify.status.open')
        }
      ]
    },
    compResults() {
      return [
        {
          id: 1,
          title: this.$vuetify.lang.t('$vuetify.call.success')
        },
        {
          id: 2,
          title: this.$vuetify.lang.t('$vuetify.call.wait')
        },
        {
          id: 3,
          title: this.$vuetify.lang.t('$vuetify.call.fail')
        }
      ]
    },
    compActionHeader() {
      let companyName = this.selectedItem.company
        ? this.selectedItem.company.name
        : this.selectedItem.company_id
      return (
        this.$vuetify.lang.t('$vuetify.buttons.action') +
        ': ' +
        this.actionTitle +
        ' - ' +
        companyName
      )
    },
    compUsers() {
      return this.$store.getters.getUsers
    },
    compAuthHeader() {
      return {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('access_token'),
          'Content-Type': 'application/json'
        }
      }
    },
    compHeader() {
      return [
        { text: 'ID', value: 'id' },
        {
          text: this.$vuetify.lang.t('$vuetify.different.title'),
          value: 'company_id',
          sortable: false
        },
        {
          text: this.$vuetify.lang.t('$vuetify.different.type'),
          value: 'company_type',
          sortable: false
        },
        {
          text: this.$vuetify.lang.t('$vuetify.user.assignTo'),
          value: 'company',
          sortable: false
        },
        { text: 'CALL1(CC)', value: 'call_1_action', sortable: false },
        { text: 'CALL2(CC/Manager)', value: 'call_2_action', sortable: false },
        { text: 'EMAIL', value: 'email_action', sortable: false },
        { text: 'CALL3(CC/Manager)', value: 'call_3_action', sortable: false },
        {
          text: this.$vuetify.lang.t('$vuetify.order.sample'),
          value: 'sample_action',
          sortable: false
        },
        { text: 'CALL4(CC/Manager)', value: 'call_4_action', sortable: false },
        {
          text: this.$vuetify.lang.t('$vuetify.order.order'),
          value: 'order_action',
          sortable: false
        },
        {
          text: this.$vuetify.lang.t('$vuetify.call.next'),
          value: 'next_call_action',
          sortable: false
        },
        {
          text: this.$vuetify.lang.t('$vuetify.different.other'),
          value: 'other_action',
          sortable: false
        },
        {
          text: this.$vuetify.lang.t('$vuetify.buttons.action'),
          value: 'action',
          sortable: false,
          width: '9%'
        }
      ]
    },
    compHistoryHeader() {
      return [
        { text: 'ID', value: 'id' },
        {
          text: this.$vuetify.lang.t('$vuetify.different.title'),
          value: 'company_id',
          sortable: false
        },
        {
          text: this.$vuetify.lang.t('$vuetify.different.type'),
          value: 'company_type',
          sortable: false
        },
        {
          text: this.$vuetify.lang.t('$vuetify.user.assignTo'),
          value: 'company',
          sortable: false
        },
        { text: 'CALL1(CC)', value: 'call_1_action', sortable: false },
        { text: 'CALL2(CC/Manager)', value: 'call_2_action', sortable: false },
        { text: 'EMAIL', value: 'email_action', sortable: false },
        { text: 'CALL3(CC/Manager)', value: 'call_3_action', sortable: false },
        {
          text: this.$vuetify.lang.t('$vuetify.order.sample'),
          value: 'sample_action',
          sortable: false
        },
        { text: 'CALL4(CC/Manager)', value: 'call_4_action', sortable: false },
        {
          text: this.$vuetify.lang.t('$vuetify.order.order'),
          value: 'order_action',
          sortable: false
        },
        {
          text: this.$vuetify.lang.t('$vuetify.call.next'),
          value: 'next_call_action',
          sortable: false
        },
        {
          text: this.$vuetify.lang.t('$vuetify.different.other'),
          value: 'other_action',
          sortable: false
        }
      ]
    },
    compShowResultSelect() {
      let actions = ['call_1', 'call_2', 'call_3', 'call_4']
      return actions.includes(this.actionName)
    },
    compShowOrderInput() {
      let actions = ['order']
      return actions.includes(this.actionName)
    },
    compShowUser() {
      let actions = ['other']
      return !actions.includes(this.actionName)
    },
    compShowDate() {
      let actions = ['other']
      return !actions.includes(this.actionName)
    },
    compDisableDate() {
      return this.action.id && this.action.marker_id === 1
    },
    compDisableOrder() {
      return this.action.id && this.action.marker_id === 1
    },
    compDisableResult() {
      return this.action.id && this.action.marker_id === 1
    }
  },
  methods: {
    viewItem(item) {
      this.selectedIndex = this.items.indexOf(item)
      this.selectedItem = Object.assign({}, item)
      this.getCompany(item)
    },
    viewHistoryItem(item) {
      this.selectedIndex = this.items.indexOf(item)
      this.selectedItem = Object.assign({}, item)
      this.dialogHistory = true
      this.getHistoryItems()
    },
    actionItem(item, actionName, actionTitle, actionObj) {
      if (this.dialogHistory) {
        this.selectedHistoryIndex = this.historyItems.indexOf(item)
        this.selectedHistoryItem = Object.assign({}, item)
      } else {
        this.selectedIndex = this.items.indexOf(item)
        this.selectedItem = Object.assign({}, item)
      }

      this.dialog = true
      this.actionTitle = actionTitle
      this.action.action_name = actionName
      this.actionName = actionName

      if (actionObj) {
        this.getAction(actionObj.id)
      } else {
        this.action.call_id = item.id
        this.action.date = this.nowDate
        this.dialog = true
      }
    },
    saveAction() {
      this.actionLoading = true

      let data = {
        id: this.action.id,
        comment: this.actionComment,
        call_id: this.action.call_id,
        marker_id: this.action.marker_id,
        date: this.action.date,
        action_name: this.action.action_name,
        order_number: this.selectedItem.order_number
      }

      this.$axios
        .post(
          this.$store.getters.getApiUrl + 'api/action/store',
          data,
          this.compAuthHeader
        )
        .then((response) => {
          this.actionLoading = false
          if (response.data.success) {
            if (this.dialogHistory)
              Object.assign(
                this.historyItems[this.selectedHistoryIndex],
                response.data.item
              )
            else
              Object.assign(this.items[this.selectedIndex], response.data.item)

            this.dialog = false

            this.snackbar.show = true
            this.snackbar.color = 'green'
            this.snackbar.text = this.$vuetify.lang.t('$vuetify.messages.saved')
          }
        })
        .catch((error) => {
          console.log(error)
          this.actionLoading = false
          this.snackbar.show = true
          this.snackbar.color = 'red'
          this.snackbar.text = this.$vuetify.lang.t('$vuetify.errors.error')
        })
    },
    getItems() {
      this.tableLoading = true
      let data = {
        search: this.search,
        page: this.options.page,
        per_page: this.options.itemsPerPage,
        sort_by: this.options.sortBy[0],
        descending: this.options.sortDesc[0],
        filters: this.filters
      }

      this.$axios
        .post(
          this.$store.getters.getApiUrl + 'api/call/index',
          data,
          this.compAuthHeader
        )
        .then((response) => {
          if (response.status === 200) {
            this.items = response.data.data
            this.totalItems = response.data.meta.total
          }
          this.tableLoading = false
        })
        .catch((error) => {
          console.log(error)
          this.tableLoading = false
        })
    },
    getHistoryItems() {
      this.tableHistoryLoading = true
      let data = {
        company_id: this.selectedItem.company_id,
        history_id: this.selectedItem.id,
        page: this.historyOptions.page,
        per_page: this.historyOptions.itemsPerPage,
        sort_by: this.historyOptions.sortBy
          ? this.historyOptions.sortBy[0]
          : null,
        descending: this.historyOptions.sortDesc
          ? this.historyOptions.sortDesc[0]
          : null
      }

      this.$axios
        .post(
          this.$store.getters.getApiUrl + 'api/call/index',
          data,
          this.compAuthHeader
        )
        .then((response) => {
          if (response.status === 200) {
            this.historyItems = response.data.data
            this.totalHistoryItems = response.data.meta.total
          }
          this.tableHistoryLoading = false
        })
        .catch((error) => {
          this.tableHistoryLoading = false
        })
    },
    getCompany(item) {
      this.loading = true
      let data = {
        id: item.company_id
      }

      this.$axios
        .post(
          this.$store.getters.getApiUrl + 'api/company/get',
          data,
          this.compAuthHeader
        )
        .then((response) => {
          if (response.status === 200) {
            this.company = response.data.data
            this.dialogCompany = true
          }
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          this.snackbar.show = true
          this.snackbar.color = 'red'
          this.snackbar.text = this.$vuetify.lang.t('$vuetify.errors.error')
        })
    },
    getAction(id) {
      this.loading = true
      let data = {
        id: id
      }

      this.$axios
        .post(
          this.$store.getters.getApiUrl + 'api/action/get',
          data,
          this.compAuthHeader
        )
        .then((response) => {
          if (response.status === 200) {
            this.action = response.data.data
            this.dialog = true
          }
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          this.snackbar.show = true
          this.snackbar.color = 'red'
          this.snackbar.text = this.$vuetify.lang.t('$vuetify.errors.error')
        })
    },
    syncUsersToCompany() {
      this.loading = true
      let users = []
      this.selectedItem.company.users.map(function(user, index) {
        users.push(user.id)
      })
      let data = {
        id: this.selectedItem.company_id,
        users: users
      }

      this.$axios
        .post(
          this.$store.getters.getApiUrl + 'api/company/users',
          data,
          this.compAuthHeader
        )
        .then((response) => {
          if (response.status === 200) {
            Object.assign(this.items[this.selectedIndex], this.selectedItem)

            this.snackbar.show = true
            this.snackbar.color = 'green'
            this.snackbar.text = this.$vuetify.lang.t('$vuetify.messages.saved')
          }
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          this.snackbar.show = true
          this.snackbar.color = 'red'
          this.snackbar.text = this.$vuetify.lang.t('$vuetify.errors.error')
        })
    },
    active(item) {
      this.loading = true
      let data = {
        id: item.id
      }

      let index = this.items.indexOf(item)

      this.$axios
        .post(
          this.$store.getters.getApiUrl + 'api/call/active',
          data,
          this.compAuthHeader
        )
        .then((response) => {
          if (response.status === 200) {
            Object.assign(this.items[index], response.data.item)

            this.snackbar.show = true
            this.snackbar.color = 'green'
            this.snackbar.text = this.$vuetify.lang.t('$vuetify.messages.saved')
          }
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          this.snackbar.show = true
          this.snackbar.color = 'red'
          this.snackbar.text = this.$vuetify.lang.t('$vuetify.errors.error')
        })
    }
  }
}
</script>
